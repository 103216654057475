<div class="spb-holder">
  <spb-header class="no-print"></spb-header>
  <router-outlet></router-outlet>
  <div class="spb-filler"></div>
  <spb-footer
    class="no-print"
    [license]="true"
    [version]="version"
    copyrightYears="2020-2024"
  ></spb-footer>
</div>
